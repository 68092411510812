import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConvertService } from '../../services/convert.service';
import { AppURL } from '../../app.url';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '../../services/alert.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
declare let pdfjsLib: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  merchants_id: any;
  constructor(private http: HttpClient,
    private build: FormBuilder,
    private param: ActivatedRoute,
    private convert: ConvertService,
    private router: Router,
    private dom: DomSanitizer,
    private alert: AlertService) { }
  menuHeight: any;
  menuid: any;
  subid: any;
  click: boolean = false;
  name: any;
  merchants: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.menuHeight = event.target.innerHeight;
  }
  ngOnInit(): void {
    this.menuHeight = window.innerHeight
    this.formControl();
    this.merchants_id = this.param.snapshot.params['id']
    this.menuid = this.param.snapshot.params['main']
    this.subid = this.param.snapshot.params['sub']
    this.name = this.param.snapshot.params['name']
    this.merchants = this.param.snapshot.params['merchants']
    if (this.merchants_id) {
      this.formMenu.get('merchants_id').setValue(this.merchants_id)
      this.formMenu.get('main_menu_id').setValue(this.menuid)
      this.getMenuItem();
    }

  }

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.menuItems, event.previousIndex, event.currentIndex);

    let i = 1
    this.menuItems.forEach((f: any) => {
      f.sort = i;
      i++;
    })

    this.http.put(`/api/Menu/${this.menuid}/SortMenu`, this.menuItems).subscribe(res => { })


  }

  formMenu: FormGroup | any;
  formControl() {
    this.formMenu = this.build.group({
      topic_name: ["", Validators.required],
      file: null,
      merchants_id: '',
      main_menu_id: '',
    })
  }

  actionMenu: boolean = false;
  menuItems: any = [];
  getMenuItem() {
    this.http.get(`/api/Menu/SubMenuAll?main_menu_id=${this.menuid}`).subscribe((res: any) => {
      this.menuItems = res.data;
      let findItems = this.menuItems.find((fn: any) => fn.topic_id == this.subid)
      if (findItems) {
        this.formMenu.get('topic_name').setValue(findItems.name)
        this.menuAtive(findItems)
      }
    })
  }

  toBack() {
    this.router.navigate(['/', this.merchants_id, this.menuid]);
  }

  addMenu() {
    this.formMenu.get('merchants_id').setValue(this.merchants_id)
    this.formMenu.get('main_menu_id').setValue(this.menuid)
    this.http.put(`/api/Menu/SubMenuAddOrEdit/0`, this.convert.paseFormData(this.formMenu.value)).subscribe((res: any) => {
      this.menuItems = res.data;
      this.getMenuItem();
      this.formReset()
    })
  }

  updateMenu() {
    // this.formMenu.get('file').setValue(null)
    this.http.put(`/api/Menu/SubMenuAddOrEdit/${this.subid}`, this.convert.paseFormData(this.formMenu.value)).subscribe((res: any) => {
      this.menuItems = res.data;
      this.getMenuItem();
      // this.formReset()
      this.actionMenu = false;
    })
  }

  formReset() {
    this.formMenu.reset();
    this.formMenu.get('merchants_id').setValue(this.merchants_id)
    this.menuAction(false);
  }

  statusButton: boolean = false;
  menuAction(action?: any, items?: any) {
    this.formMenu.get('merchants_id').setValue(this.merchants_id)
    this.formMenu.get('main_menu_id').setValue(this.menuid)
    if (items) {
      this.subid = items.topic_id;
      this.formMenu.get('topic_name').setValue(items.name)
      this.statusButton = true
    }
    else {
      this.formMenu.get('topic_name').setValue("")
      this.statusButton = false
    }
    this.actionMenu = action;
  }

  itemModel: any;
  menuAtive(items: any) {
    if (this.click) return;

    this.itemModel = items;
    this.subid = items.topic_id;
    this.menuItems.forEach((f: any) => f.active = false);
    items.active = true;
    this.formMenu.get('topic_name').setValue(items.name)
    this.formMenu.get('file').setValue(null)
    if (this.itemModel.link) {
      this.haveFile = true;
      this.loadPDF(`https://venezia.onlinepos.me/uploads/${this.itemModel.link}`);
    }
    else this.haveFile = false;
    this.router.navigate(['/', AppURL.menu, this.merchants_id, this.menuid, this.merchants, this.name, items.topic_id]);
  }


  // pdfurl: any = 'https://images.tusatopenhouse.com/WebImage/menucoffee.pdf';
  loadPDF(file?: any) {
    if (!this.haveFile) return;
    setTimeout(() => {
      let canvasDom: any = document.getElementById('canvas-dom');
      canvasDom.innerHTML = "";
      pdfjsLib.getDocument(file).then((doc: any) => {
        for (let i = 0; i < doc._pdfInfo.numPages; i++) {
          let id = i + 1
          doc.getPage(id).then((page: any) => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = page.getViewport(1).width;
            canvas.height = page.getViewport(1).height;
            canvas.classList.add('pdf-viewer');
            canvasDom.appendChild(canvas);
            page.render({
              canvasContext: ctx,
              viewport: page.getViewport(1)
            })
          })
        }
      })

    }, 500);

  }

  haveFile: boolean = false;
  upload(event: any) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      this.haveFile = true;
      this.uploadImage(event)
      if (file.type == 'application/pdf' && file.name.split(".")[1] == "pdf") return;
      event.target.value = "";
      this.haveFile = false;
      return alert("ต้องเป็นรูปภาพ หรือ PDF เท่านั้น");
    }

  }

  fileUpload: any;
  uploadImage(event: any) {
    const file = event.target.files && event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      this.formMenu.get('file').setValue(file)
      this.loadPDF((<FileReader>event.target).result);
      this.updateMenu()
    }
  }


  delete(items: any) {
    this.alert.confirm('ต้องการลบเมนูหรือไม่').then(con => {
      if (con) this.http.delete(`/api/Menu/SubMenuDeleted/${items.topic_id}`).subscribe(res => {
        this.getMenuItem();
      }, err => this.alert.dialog(err.error.message))
    })
  }

}
