import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppURL } from '../../app.url';
import { ConvertService } from '../../services/convert.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private convert: ConvertService,
    private alert: AlertService

  ) { }
  click: boolean = false;
  merchantsid: any;
  mainid: any;
  ngOnInit(): void {
    this.merchantsid = this.activatedRoute.snapshot.params['id']
    this.mainid = this.activatedRoute.snapshot.params['main']
    this.getMain();
    setTimeout(() => {
      if (this.merchantsid) {
        let filemenuItem = this.menuItem.find((fn: any) => fn.merchants_id == this.merchantsid)
        this.actionMenu(filemenuItem)
        if (this.mainid) {
          let findItems = this.menuItem.find((fn: any) => fn.merchants_id == this.merchantsid);
          this.actionMenu(findItems)
        }
      }
      else this.viewMenu = false;
    }, 500);
  }
  menuItem: any = [];
  viewMenu: boolean = true;
  getMain() {
    this.http.get(`/api/Merchants/MerchantsBindUser`).subscribe((res: any) => {
      this.menuItem = res.data;
      // let filemenuItem = this.menuItem.find((fn: any) => fn.merchants_id == this.merchantsid)
      if (this.menuItem.length > 0) this.actionMenu(this.menuItem[0])
    })

  }

  MenuMain: any = [];
  actionMenu(items: any) {
    // if (this.click) return;
    this.merchantsid = items.merchants_id;
    this.http.get(`/api/Menu/MenuMainAll?merchants_id=${items.merchants_id}`).subscribe((res: any) => {
      this.viewMenu = true;
      this.MenuMain = res.data;
      if (this.mainid) { this.router.navigate(['/', items.merchants_id, this.mainid]); return; }
      else this.router.navigate(['/', items.merchants_id]);
    })

  }

  actionSubMenu(items: any) {
    if (this.click) return;
    let findImage = this.menuItem.find((fn: any) => fn.merchants_id == this.merchantsid);
    this.router.navigate(['/', AppURL.menu, items.merchants_id, items.main_menu_id, findImage.merchants_name, items.name])
  }


  addRm() {

  }

  addMenuMain(key?: any) {
    if (key == 'main') this.MenuMain.push({
      main_menu_id: 0,
      merchants_id: this.merchantsid,
      name: "กรอกชื่อเมนู",
      edit: true,
      create: true
    })
    else this.menuItem.push({
      burn: false,
      earn: false,
      merchants_id: 0,
      merchants_name: "กรอกชื่อร้าน",
      edit: true,
      create: true
    })
  }

  delButtonAdd(items: any, key?: any) {
    if (key == 'main') this.MenuMain.splice(this.MenuMain.findIndex((del: any) => del.main_menu_id == items.main_menu_id), 1)
    else this.menuItem.splice(this.menuItem.findIndex((del: any) => del.merchants_id == items.merchants_id), 1)
  }

  buttonCreateMenu(items: any, input: any, key?: any) {
    if (!input.value) this.alert.dialog('กรอกชื่อเมนูหลัก');
    if (key == 'main') {
      let model = { merchants_id: this.merchantsid, name: input.value }
      this.http.post(`/api/Menu/MainMenuCreated`, this.convert.paseFormData(model)).subscribe(res => {
        this.actionMenu(items);
        items.edit = false;
        items.name = input.value;
        items.create = false;
      }, err => this.alert.dialog(err.error.message))
    }
    else {
      let model = { name: input.value, burn: true, earn: true }
      this.http.post(`/api/Merchants/Created`, model).subscribe(res => {
        this.getMain();
      }, err => this.alert.dialog(err.error.message))
    }

  }

  toHome() {
    this.viewMenu = false;
    this.router.navigate(['/'])
  }


  editButton(items: any, key?: any) {
    if (key == 'main') this.MenuMain.forEach((f: any) => f.edit = false);
    else this.menuItem.forEach((f: any) => f.edit = false);
    items.edit = true;
  }

  delButton(items: any, key?: any) {
    let name = '';
    if (key == 'main') name = 'ต้องการลบเมนูหลักหรือไม่';
    else name = 'ต้องการลบร้านหรือไม่';
    this.alert.confirm(name).then(con => {
      if (con) {
        if (key == 'main') this.http.delete(`/api/Menu/MainMenuDeleted/${items.main_menu_id}`).subscribe(res => {
          this.actionMenu(items);
        }, err => this.alert.dialog(err.error.message))
        else this.http.delete(`/api/Merchants/Deleted/${items.merchants_id}`).subscribe(res => {
          this.getMain();
        }, err => this.alert.dialog(err.error.message))
      }


    })

  }

  updateButton(items?: any, input?: any, key?: any) {
    let model = {
      name: input.value,
      main_menu_id: items.main_menu_id,
      merchants_id: items.merchants_id,
    }
    if (key == 'main') this.http.put(`/api/Menu/MainMenuUpdated/${items.main_menu_id}`, this.convert.paseFormData(model)).subscribe(res => {
      items.edit = false;
      this.actionMenu(items);
    }, err => this.alert.dialog(err.error.message))
    else {
      let model = { name: input.value, burn: true, earn: true }
      this.http.post(`/api/Merchants/Updated/${items.merchants_id}`, model).subscribe(res => {
        this.getMain();
      }, err => this.alert.dialog(err.error.message))
    }

  }



}
