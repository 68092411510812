import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfig } from '../configs/app.config';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard extends AppConfig implements CanActivate, CanActivateChild {
  constructor(
    private _auth: AuthService,
    private _router: Router,
  ) {
    super();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._CanActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._CanActivate(route, state);
  }

  private _CanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Promise<boolean | UrlTree> | boolean {
    if (this._auth.accessToken) return true;
    return this._router.navigate(['/',
      // this.AppURL.account,
      // this.AccountURL.signin,
      'sigin'
    ]);
  }

}
