<div class="setting-menu">
    <div class="box-menu" [ngStyle]="{'height.px':menuHeight - 76}">
        <div class="box-header">
            <!--  -->
            <button (click)="toBack()">
                <div class="material-icons">arrow_back_ios</div>
            </button>
            <h1>{{merchants}}</h1>
        </div>
        <div class="box-header sub">
            <h5>{{name}}</h5>
        </div>
        <div class="box-items" [ngStyle]="{'max-height.px': menuHeight - 350}">
            <!-- <ul>
                <ng-container *ngFor="let menu of menuItems">
                    <li class="menu-list" [ngClass]="{'active':menu.active}" (click)="menuAtive(menu)">
                        <a>{{menu.name}}</a>
                        <span class="material-icons">more_vert</span>
                        <span class="edit" (mouseover)="click = true" (mouseleave)="click = false" (click)="menuAction(true,menu)">แก้ไข</span>
                        <span class="del" (mouseover)="click = true" (mouseleave)="click = false" (click)="delete(menu)">ลบ</span>
                    </li>
                </ng-container>
                <li class="end" *ngIf="actionMenu">
  
                </li>
                <li class="end" *ngIf="!actionMenu" (click)="menuAction(true)"><a>เพิ่มเมนู</a></li>
            </ul> -->
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let menu of menuItems" cdkDrag (click)="menuAtive(menu)">
                    <div class="text-name">
                        {{menu.name}}
                    </div>
                    <div class="flex">
                        <span class="edit me-1" (mouseover)="click = true" (mouseleave)="click = false" (click)="menuAction(true,menu)">แก้ไข</span>
                        <span class="del" (mouseover)="click = true" (mouseleave)="click = false" (click)="delete(menu)">ลบ</span>
                        <span class="icon material-icons">more_vert</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="flex float-end mt-3 me-3" style="cursor: pointer" *ngIf="!actionMenu" (click)="menuAction(true)">
            เพิ่มเมนู
        </p>
        <div *ngIf="actionMenu" class="box-add" [formGroup]="formMenu">
            <div class="row">
                <div class="col-12">
                    <input type="text" pInputText formControlName="topic_name" />
                    <br>
                    <br>
                </div>
                <div class="col-6">
                    <button pButton class="w-100 p-button-danger" (click)="menuAction(false)">ยกเลิก</button>
                </div>
                <div class="col-6">
                    <button pButton class="w-100 p-button-info" (click)="addMenu()" *ngIf="!statusButton">บันทึก</button>
                    <button pButton class="w-100 p-button-info" (click)="updateMenu()" *ngIf="statusButton">แก้ไข</button>
                </div>
            </div>
        </div>
    </div>
    <div class="box-content" *ngIf="itemModel">
        <section *ngIf="haveFile">
            <div class="inner" id="canvas-dom" [ngStyle]="{'max-height.px':menuHeight - 76}">
            </div>
        </section>
        <input class="file" type="file" #file (change)="upload($event)">
        <img class="upload" *ngIf="!haveFile" src="./../../../assets/images/icons/Upload.png" alt="" (click)="file.click()">
        <img class="upload update" *ngIf="haveFile" src="./../../../assets/images/icons/Edit.png" alt="" (click)="file.click()">
        <!-- <div class="box-upload" *ngIf="!itemModel.image">
            <H1>Upload</H1>
        </div> -->
    </div>
</div>