import { environment } from 'src/environments/environment';
import { AppURL } from '../app.url';
import { BookingTVURL } from '../bookingTV/booking-tv.url';
import { MenulistURL } from '../menu/menu-list.url';



export class AppConfig {
  /** origin url */
  originURL = environment.origin;

  /**  root url */
  AppURL = AppURL;

  /** account url */

  /** booking url */

  
  //** TV URL */
  BookingTV = BookingTVURL;

  //**menu list */
  Menulist = MenulistURL;
}
