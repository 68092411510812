import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppURL } from './app.url';
import { AuthGuard } from './guards/auth.guard';
import { NotfoundComponent } from './modules/exception/notfound/notfound.component';
import { MenuComponent } from './modules/menu/menu.component';
import { HomeComponent } from './modules/home/home.component';
import { SigninComponent } from './modules/signin/signin.component';
import { UnauthGuard } from './guards/unauth.guard';

const routes: Routes = [
  // Redirect signin first
  { path: '', redirectTo: '/', pathMatch: 'full' },

  // Account routing
  {
    path: AppURL.sigin, children: [
      { path: '', component: SigninComponent, canActivate: [UnauthGuard] },
    ]
  },
  {
    path: AppURL.home, canActivate: [AuthGuard], children: [
      { path: '', component: HomeComponent },
      { path: ':id', component: HomeComponent },
      { path: ':id/:main', component: HomeComponent }
    ]
  },
  {
    path: AppURL.menu, canActivate: [AuthGuard], children: [
      { path: ':id', component: MenuComponent },
      { path: ':id/:main/:merchants/:name', component: MenuComponent },
      { path: ':id/:main/:merchants/:name/:sub', component: MenuComponent }
    ]
  },
  // Show Tv
  // {
  //   path: AppURL.line,
  //   loadChildren: () => import('./modules/line/line.module').then(m => m.LineModule)
  // },

  // Another routing
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
