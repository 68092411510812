import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }

  //#endregion 

  /** แปลงข้อมูลจาก json เป็น FormData */
  paseFormData(json: Object) {
    const form = new FormData();
    (function serializeObjects(data: Object, key?: string) {
      Object.entries(data || {}).forEach(([name, value]) => {
        const fieldName = key ? `${key}[${name}]` : name;
        switch (typeof value) {
          case 'object':
            // เปลี่ยนชื่อใหม่หากเป็นรูปแบบ Array [] ต้องเป็น .
            if (value instanceof File || value instanceof Blob) form.append(fieldName.replace(`[${name}]`, `.${name}`), value);
            else serializeObjects(value, fieldName);
            break;
          case 'string':
            form.append(fieldName, value);
            break;
          case 'number':
          case 'boolean':
          case 'bigint':
            form.append(fieldName, value.toString());
            break;
        }
      });
    })(json);
    return form;
  }

  /** แปลงข้อมูล base64 เป็น blob */
  parseBlobBase64(rawBase64: string) {
    const replaces = rawBase64.split(",");
    const type = replaces[0].replace("data:", "").replace(";base64", "");
    const b64Data = replaces[1];
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++)
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  }


  parseNumber(number: any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  thaiDate(date: any, type?: string) {
    let year = moment(date).add(543, 'year').format('YYYY')
    let month = this.converMonth(moment(date).format('MM'))
    let day = moment(date).format('DD')
    let hh = moment(date).format('hh');
    let mm = moment(date).format('mm');
    let ss = moment(date).format('ss');
    let convertDate = `${day} ${month} ${year}`;
    if (type == 'Datetime') { convertDate = `${day} ${month} ${year} เวลา ${hh}:${mm}:${ss}`; return convertDate; }
    if (type == 'datetime') { convertDate = `${day} ${month} ${year} ${hh}:${mm}:${ss}`; return convertDate; }
    if (type == 'time') { convertDate = `${hh}:${mm}:${ss}`; return convertDate; }
    else return convertDate;


  }


  converMonth(m: string) {
    if (m == '01') return 'มกราคม';
    if (m == '02') return 'กุมภาพันธ์';
    if (m == '03') return 'มีนาคม';
    if (m == '04') return 'เมษายน';
    if (m == '05') return 'พฤษภาคม';
    if (m == '06') return 'มิถุนายน';
    if (m == '07') return 'กรกฎาคม';
    if (m == '08') return 'สิงหาคม';
    if (m == '09') return 'กันยายน';
    if (m == '10') return 'ตุลาคม';
    if (m == '11') return 'พฤศจิกายน';
    if (m == '12') return 'ธันวาคม';
    return;
  }

  LocaltionItems(locationItems: any) {
    for (let i = 0; i < 256; i++) {
      if (i == 0) locationItems.push({ id: i, code: i, name: `สำนักงานใหญ่` })
      else {
        let numberLocaltion: any = this.converLocaltion(i);
        locationItems.push({ id: i, code: numberLocaltion, name: `สาขา ${numberLocaltion}` })
      }
    }
    return locationItems;
  }

  converLocaltion(i: any) {
    let numberLocaltion: any = '';
    if (i < 100000) { numberLocaltion = i; }
    if (i < 10000) { numberLocaltion = '0' + i; }
    if (i < 1000) { numberLocaltion = '00' + i; }
    if (i < 100) { numberLocaltion = '000' + i; }
    if (i < 10) { numberLocaltion = '0000' + i; }
    return numberLocaltion;
  }
}
