import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SigninService } from './signin.service';
import { AlertService } from '../../services/alert.service';
import { AppURL } from '../../app.url';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  /** redirect url */
  redirectURL: string;

  /** loading phone submit */
  loading: boolean = false;
  constructor(private http: HttpClient,
    private _form: FormBuilder,
    private _account: SigninService,
    private _alert: AlertService,
    private _router: Router,
    private _activateRoute: ActivatedRoute, ) {
    this.redirectURL = this._activateRoute.snapshot.params._ || ['', AppURL.home].join('/');
  }

  ngOnInit(): void {
    this.formControl();
  }


  onSubmit() {
    if (this.formSigin.invalid) return this.formSigin.markAllAsTouched();
    this.loading = true;
    this._account
      .onSigninUser(this.formSigin.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        this._router.navigateByUrl(this.redirectURL);
      }, error => this._alert.notify(error.message));
  }

  // private createForm() {
  //   this.formSigin = this._form.group({
  //     UserName: ['', Validators.required],
  //     Password: ['', Validators.required],
  //     DeviceName: ['66', Validators.required],
  //   });
  // }

  formSigin?: FormGroup | any;
  formControl() {
    this.formSigin = this._form.group({
      // UserName: "Houfu",
      // Password: "Houfu123!",
      // DeviceName: "pc"
      UserName: "",
      Password: "",
      DeviceName: "pc"
    })
  }
}
