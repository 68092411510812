import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
  ) {
    // super();
  }

  /**  on phone login */
  onSigninUser(data: any) {
    data.UserName = data.UserName.replace(/^0/, '66');
    return this._http
      .post('/api/Authentication/SignIn/Password', data)
      .pipe(map((res) => this._auth.initalizeLoadUserLogin().subscribe()));
  }
}
