<!-- <button class="btn btn-primary btn-back" *ngIf="viewMenu" (click)="toHome()">
    <span class="material-icons ">
        arrow_back_ios
    </span>
    ย้อนกลับ
</button> -->
<!-- <button class="btn btn-primary btn-add" *ngIf="!viewMenu && menuItem.length == 0" (click)="addMenuMain()">
    <span class="material-icons">
        add_circle_outline
    </span>
    เพิ่มร้าน
</button> -->
<button class="btn btn-primary btn-add" *ngIf="viewMenu" (click)="addMenuMain('main')">
    <span class="material-icons">
        add_circle_outline
    </span>
    เพิ่มเมนูหลัก
</button>
<div class="box-image">
    <img src="../../../assets/images/bg-kn.jpg" alt="">
    <div class="box-color"></div>
</div>
<div class="box-content">
    <!-- ร้าน -->
    <ng-container *ngIf="!viewMenu">
        <div class="row" *ngFor="let menu of menuItem">
            <div class="col-12">
                <button class="btn btn-default button" (click)="actionMenu(menu)">
                    <ng-container *ngIf="!menu.edit">
                        <span>
                            {{menu.merchants_name}}
                        </span>
                        <span class="material-icons edit" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="editButton(menu)">
                            edit
                        </span>
                        <!-- <span class="material-icons del" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="delButton(menu)">
                            delete
                        </span> -->
                    </ng-container>
                    <ng-container *ngIf="menu.edit">
                        <input type="text" class="input-edit" pinputtext [placeholder]="menu.merchants_name"
                            #inputNameMerchants (mouseover)="click = true" (mouseleave)="click = false">
                        <!-- UPDATE -->
                        <span class="material-icons done" *ngIf="!menu.create" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="updateButton(menu,inputNameMerchants)">
                            check_circle
                        </span>
                        <span class="material-icons cancel" (mouseover)="click = true" (mouseleave)="click = false"
                            *ngIf="!menu.create" (click)="menu.edit = false">
                            clear
                        </span>
                        <!-- UPDATE -->
                        <!-- CREATE -->
                        <span class="material-icons done" *ngIf="menu.create" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="buttonCreateMenu(menu,inputNameMerchants)">
                            check_circle
                        </span>
                        <span class="material-icons cancel" (mouseover)="click = true" (mouseleave)="click = false"
                            *ngIf="menu.create" (click)="delButtonAdd(menu)">
                            clear
                        </span>
                        <!-- CREATE -->
                    </ng-container>
                </button>
            </div>
        </div>
    </ng-container>
    <!-- Amin Menu -->
    <ng-container *ngIf="viewMenu">
        <div class="row" *ngFor="let menu of MenuMain">
            <div class="col-12">
                <button class="btn btn-default button" (click)="actionSubMenu(menu)">
                    <ng-container *ngIf="!menu.edit">
                        <span>
                            {{menu.name}}
                        </span>
                        <span class="material-icons edit" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="editButton(menu,'main')">
                            edit
                        </span>
                        <span class="material-icons del" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="delButton(menu,'main')">
                            delete
                        </span>
                    </ng-container>
                    <ng-container *ngIf="menu.edit">
                        <input type="text" class="input-edit" pinputtext [placeholder]="menu.name" #inputName
                            (mouseover)="click = true" (mouseleave)="click = false">
                        <!-- UPDATE -->
                        <span class="material-icons done" *ngIf="!menu.create" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="updateButton(menu,inputName,'main')">
                            check_circle
                        </span>
                        <span class="material-icons cancel" (mouseover)="click = true" (mouseleave)="click = false"
                            *ngIf="!menu.create" (click)="menu.edit = false">
                            clear
                        </span>
                        <!-- UPDATE -->
                        <!-- CREATE -->
                        <span class="material-icons done" *ngIf="menu.create" (mouseover)="click = true" (mouseleave)="click = false"
                            (click)="buttonCreateMenu(menu,inputName,'main')">
                            check_circle
                        </span>
                        <span class="material-icons cancel" (mouseover)="click = true" (mouseleave)="click = false"
                            *ngIf="menu.create" (click)="delButtonAdd(menu,'main')">
                            clear
                        </span>
                        <span *ngIf="menu.create"> 123</span>
                        <!-- CREATE -->
                    </ng-container>
                </button>
            </div>
        </div>
    </ng-container>
</div>