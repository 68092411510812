<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<div class="box-image">
    <img src="../../../assets/images/icons/bg.png" alt="" width="100%">
</div>
<div class="container d-none d-block-lx d-block-lg ">
    <br><br>
    <br><br>
    <br><br>
</div>
<ng-cotnainer [formGroup]="formSigin">
    <div class="box-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-12">
                    <p>ผู้เข้าใช้งาน</p>
                    <input type="text" pInputText formControlName="UserName">
                </div>
            </div>
            <br>
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-12">
                    <p>รหัสผ่าน</p>
                    <input type="password" pInputText formControlName="Password">
                </div>
            </div>
            <br>
            <br>
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-12">
                    <div class="row">
                        <div class="col-12">
                            <button pButton class="w-100 text-center login" (click)="onSubmit()">เข้าระบบ</button>
                        </div>
                        <!-- <div class="col-6"></div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-cotnainer>