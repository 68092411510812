<div class="box-navbar">
    <div class="logo">
        <img src="../../../assets/images/icons/logo.png" alt="" width="160px">
    </div>
</div>
<router-outlet></router-outlet>
<p-toast position="bottom-right"></p-toast>
<p-confirmDialog key="confirm" [style]="{width: '320px'}"></p-confirmDialog>
<p-confirmDialog #dl key="dialog" [style]="{width: '320px'}">
    <ng-template pTemplate="footer">
        <button pButton type="button" class="p-button-sm p-button-rounded" icon="pi pi-check" [label]="dl.acceptButtonLabel"
            (click)="dl.accept()"></button>
    </ng-template>
</p-confirmDialog>